import { gamesList, winMultipliersPlinko, winMultipliersPlinko0, winMultipliersPlinko1, winMultipliersFrogCrossing, plinkoBaseColors, dragonTowerRowConfigs } from './tequityData';

export const useFairnessVerificationStore = defineStore('fairnessVerificationStore', () => {
  const gamesStore = useGamesStore();
  const { gameServerUrl, } = storeToRefs(gamesStore);
  const uiStore = useUiStore();
  const { toastNotification, showFairnessModal, } = storeToRefs(uiStore);
  const { $rollbar, $api, } = useNuxtApp();

  const gameFairnessData = ref(null);
  const gameResult = ref(null);
  const activeGames = ref([]);

  const clientSeed = ref(null);
  const serverSeed = ref(null);
  const serverSeedHash = ref(null);
  const nextServerSeedHash = ref(null);
  const nonce = ref(0);
  const unfinishedGames = ref([]);
  const status = ref(null);

  // Game setup
  const multiplier = ref(0);
  const mines = ref(1);
  const rows = ref(8);
  const risk = ref('low');
  const stopIndex = ref(10);
  const difficulty = ref('easy');
  const currency = ref('Unknown currency');

  const hasUnfinishedGames = computed(() => unfinishedGames.value.length > 0);
  const isMultiplayerGame = computed(() => !!gameFairnessData.value?.isMultiplayerGame);

  function getActiveGames() {
    try {
      const games = gamesList();
      activeGames.value = games.filter(game => game.isActive);
    } catch (err) {
      activeGames.value = [];
    }
  }

  async function getRoundData() {
    try {
      if (!gameServerUrl.value || !gameFairnessData.value.roundId) {
        throw new Error('Game server URL or round ID is not set');
      }
      const response = await $api(`${gameServerUrl.value}/game/replay`, {
        method: 'GET',
        params: {
          roundId: gameFairnessData.value.roundId,
        },
      });
      return response;
    } catch (err) {
      $rollbar.error('Failed to fetch round data', err);
      throw err;
    }
  }

  async function getActiveRngSeeds() {
    try {
      const response = await window.mwgame.getActiveRngSeeds();
      return response;
    } catch (err) {
      $rollbar.error('Failed to get ActiveRngSeeds', err);
      throw err;
    }
  }

  async function roundRngState() {
    try {
      if (!gameServerUrl.value || !gameFairnessData.value.roundId) {
        throw new Error('Game server URL or round ID is not set');
      }
      const response = await $api(`${gameServerUrl.value}/fairness/roundRngState`, {
        method: 'GET',
        params: {
          roundId: gameFairnessData.value.roundId,
        },
      });
      return response;
    } catch (err) {
      $rollbar.error('Failed to fetch roundRngState', err);
      throw err;
    }
  }

  async function drawRngState() {
    try {
      if (!gameServerUrl.value || !gameFairnessData.value.drawId) {
        throw new Error('Game server URL or draw ID is not set');
      }
      const response = await $api(`${gameServerUrl.value}/fairness/drawRngState`, {
        method: 'GET',
        params: {
          drawId: gameFairnessData.value.drawId,
        },
      });
      return response;
    } catch (err) {
      $rollbar.error('Failed to fetch drawRngState', err);
      throw err;
    }
  }

  function setGameSpecificData(wagers, responseRoundData) {
    let localMines = 1;
    let localRows = 8;
    let localRisk = 'low';
    let localDifficulty = 'easy';
    let localStopIndex = 10;
    let localMultiplier = 0;
    currency.value = responseRoundData?.currency ?? 'Unknown currency';

    if (!wagers) {
      mines.value = localMines;
      rows.value = localRows;
      risk.value = localRisk;
      difficulty.value = localDifficulty;
      stopIndex.value = localStopIndex;
      multiplier.value = isMultiplayerGame.value ? getWinMultiplier(responseRoundData, gameFairnessData.value.name, localRows, localRisk) : localMultiplier;
      return;
    }

    if (wagers?.data?.numberOfMines !== undefined) {
      localMines = wagers.data.numberOfMines;
    }

    if (wagers?.data?.rows !== undefined || wagers?.params?.rows !== undefined) {
      localRows = wagers.data.rows ?? wagers.params.rows;
    }

    if (wagers?.data?.risk !== undefined || wagers?.params?.risk !== undefined) {
      localRisk = wagers.data.risk ?? wagers.params.risk;
    }

    if (wagers?.data?.difficulty !== undefined || wagers?.params?.difficulty !== undefined) {
      localDifficulty = wagers.data.difficulty ?? wagers.params.difficulty;
    }

    // Frog Crossing
    if (responseRoundData?.round?.wagers) {
      localStopIndex = getFrogCrossingStopIndex(responseRoundData.round.wagers);
    }

    if (responseRoundData && gameFairnessData.value?.name) {
      localMultiplier = getWinMultiplier(
        responseRoundData,
        gameFairnessData.value.name,
        localRows,
        localRisk
      );
    }

    mines.value = localMines;
    rows.value = localRows;
    risk.value = localRisk;
    difficulty.value = localDifficulty;
    stopIndex.value = localStopIndex;
    multiplier.value = localMultiplier;
  }

  async function getInitialData() {
    try {
      let wagers = null;
      let roundData = null;
      // Initial setup for multiplayer game
      if (isMultiplayerGame.value) {
        if (!gameFairnessData.value.drawId) {
          // Draw ID is not set
          toastNotification.value = {
            type: 'warning',
            title: 'Round is not finished yet',
            content: 'Please try again later',
            closeAfter: 3000,
          };
          showFairnessModal.value = false;
          return;
        }

        const requests = [
          () => drawRngState(),
          () => getActiveRngSeeds(),
        ];

        const [
          responseRngState,
          responseActiveSeeds,
        ] = await Promise.allSettled(requests.map(requestFn => requestFn()));

        if (responseRngState.status === 'fulfilled') {
          serverSeed.value = responseRngState.value.seed;
          serverSeedHash.value = responseRngState.value.hash;
        } else {
          throw new Error('Round is not finished yet');
        }

        if (responseActiveSeeds.status === 'fulfilled') {
          clientSeed.value = responseActiveSeeds?.value?.clientSeed;
          nextServerSeedHash.value = responseActiveSeeds?.value?.nextServerSeedHash;
          nonce.value = responseActiveSeeds?.value?.nonce;
          unfinishedGames.value = responseActiveSeeds?.value?.unfinishedGames;
        } else {
          throw new Error('Failed to get active seeds data');
        }
      } else {
        // Initial setup for singleplayer game
        const requests = [
          () => roundRngState(),
          () => getActiveRngSeeds(),
          () => getRoundData(),
        ];
        const [
          responseRngState,
          responseActiveSeeds,
          responseRoundData,
        ] = await Promise.allSettled(requests.map(requestFn => requestFn()));

        clientSeed.value = responseActiveSeeds?.value?.clientSeed;
        nextServerSeedHash.value = responseActiveSeeds?.value?.nextServerSeedHash;
        nonce.value = responseActiveSeeds?.value?.nonce;
        serverSeed.value = responseRngState?.value?.serverSeed;
        serverSeedHash.value = responseActiveSeeds?.value?.serverSeedHash;
        unfinishedGames.value = responseActiveSeeds?.value?.unfinishedGames;
        status.value = responseRngState?.value?.status;

        wagers = responseRoundData?.value?.round?.wagers?.[0];
        roundData = responseRoundData?.value;
      }

      setGameSpecificData(wagers, roundData);
    } catch (err) {
      toastNotification.value = {
        type: 'warning',
        title: 'Something went wrong',
        content: `Please try again later: ${err.message}`,
        closeAfter: 3000,
      };
      showFairnessModal.value = false;
    }
  }

  function getFrogCrossingStopIndex(wagers) {
    const lastWagerIndex = wagers.length - 1;
    const isAutobet = wagers[0].action === 'autobet';
    const userEnded = !!wagers[lastWagerIndex].data.reachedEnd && !!wagers[lastWagerIndex].data.survived;
    const userCashOut = wagers[lastWagerIndex].action === 'takeWin';

    if (isAutobet) {
      return Math.min(wagers[0].data?.stopIndex, wagers[0].data?.deathPoint);
    } else if (userEnded) {
      return lastWagerIndex;
    } else if (userCashOut) {
      return wagers[lastWagerIndex - 1].state.currentStep;
    } else {
      // lose case
      return wagers.length < 3 ? 1 : lastWagerIndex;
    }
  }

  function getWinMultiplier(response, gameName, rows = 8, risk = 'low') {
    const games = gamesList();
    const game = games.find(game => game.name === gameName);
    if (!game) {
      return 0;
    }
    switch (game.id) {
      case 'mines': {
        const lastWagerIndex = response.round.wagers.length - 1;
        const penultimateWagerIndex = lastWagerIndex - 1 < 1 ? 0 : lastWagerIndex - 1;
        const lastWager = response.round.wagers[lastWagerIndex];
        const lastWagerMultiplier = +Number(lastWager.data.multiplier).toFixed(2);
        const penultimateWagerMultiplier = +Number(response.round.wagers[penultimateWagerIndex].data.multiplier).toFixed(2);
        return lastWagerMultiplier >= 1 ? lastWagerMultiplier : penultimateWagerMultiplier;
      }
      case 'dragon-tower': {
        const lastWagerIndex = response.round.wagers.length - 1;
        const takeWin = response.round.wagers[lastWagerIndex].action === 'takeWin';
        return takeWin ? +Number(response.round.wagers[lastWagerIndex].data.multiplier).toFixed(2) : +Number(response.round.wagers[lastWagerIndex - 1].data.nextMultiplier).toFixed(2);
      }
      case 'dice':
        return +response.round.wagers[0].data.winMultiplier;
      case 'keno':
      case 'diamonds':
        return +response.round.wagers[0].data.wonMultiplier;
      case 'limbo':
        return +response.round.wagers[0].params.multiplier;
      case 'plinko':
      case 'plinko0':
      case 'plinko1':
        return +response.config.winMultipliers[rows][risk][response.round.wagers[0].data.multiplierIndex];
      case 'frog-crossing': {
        const lastWagerIndex = response.round.wagers.length - 1;
        const penultimateWagerIndex = lastWagerIndex - 1 < 1 ? 0 : lastWagerIndex - 1;
        const gameDifficulty = response.round.wagers[penultimateWagerIndex].state.difficulty ?? response.round.wagers[penultimateWagerIndex].params.difficulty;
        return +winMultipliersFrogCrossing[gameDifficulty][stopIndex.value];
      }
      case 'crash0':
      case 'crash1': {
        if (!gameFairnessData.value.buyin || gameFairnessData.value.buyin === 0) {
          return 0;
        }
        return gameFairnessData.value.payout / gameFairnessData.value.buyin;
      }
      default:
        return 0;
    }
  }

  function generateNewClientSeed() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const length = Math.floor(Math.random() * 3) + 8; // Random length between 8 and 10
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  async function updateClientSeed(clientId) {
    try {
      const response = await window.mwgame.updateClientSeed(clientId);
      return response;
    } catch (err) {
      $rollbar.error('Failed to update client seed', err);
      throw err;
    }
  }

  async function proveFairness(payload) {
    if (!gameServerUrl.value) {
      throw new Error('Game server URL is not set');
    }
    try {
      const response = await $api(`${gameServerUrl.value}/game/proveFairness`, {
        method: 'GET',
        params: { provider: 'tequity', ...payload, },
      });
      return response;
    } catch (err) {
      $rollbar.error('Failed to prove fairness', err);
      throw err;
    }
  }

  async function handleUpdateClientSeed(clientId) {
    if (hasUnfinishedGames.value) {
      toastNotification.value = {
        type: 'warning',
        title: 'Finish your games',
        content: 'You need to finish all your games before you can rotate your seed',
        closeAfter: 3000,
      };
      return;
    }
    const newClientSeed = clientId.length && clientId !== clientSeed.value ? clientId : generateNewClientSeed();
    const response = await updateClientSeed(newClientSeed);
    if (response) {
      clientSeed.value = response.clientSeed;
      nextServerSeedHash.value = response.nextServerSeedHash;
      nonce.value = response.nonce;
      serverSeedHash.value = response.serverSeedHash;
    }
  }

  async function handleRotateSeed() {
    if (hasUnfinishedGames.value) {
      toastNotification.value = {
        type: 'warning',
        title: 'Finish your games',
        content: 'You need to finish all your games before you can rotate your seed',
        closeAfter: 3000,
      };
      return;
    }
    const response = await updateClientSeed(clientSeed.value);

    if (response) {
      clientSeed.value = response.clientSeed;
      nextServerSeedHash.value = response.nextServerSeedHash;
      nonce.value = response.nonce;
      serverSeedHash.value = response.serverSeedHash;
    }
  }

  function limboResult(result) {
    const lastRnIndex = result.randomizations.length - 1;
    const gameData = result.randomizations[lastRnIndex];
    return gameData.gameEvent.result;
  }

  function diceResult(result) {
    const gameData = result.randomizations[0];
    return gameData.gameEvent.roll;
  }

  function crashResult(result) {
    const gameData = result.randomizations[0];
    return +Number(gameData.gameEvent.crashPointMultiplier).toFixed(2);
  }

  function getMinesPosition(gameData, mines) {
    const minesPosition = gameData.gameEvent.minesPositionsBuffer;
    return minesPosition ? minesPosition.slice(0, mines) : [];
  }

  function minesResult(result, mines) {
    const lastRnIndex = result.randomizations.length - 1;
    const gameData = result.randomizations[lastRnIndex];

    const minesPosition = getMinesPosition(gameData, mines);
    return minesPosition.sort((a, b) => a - b);
  }

  function kenoResult(result) {
    const lastRnIndex = result.randomizations.length - 1;
    const gameData = result.randomizations[lastRnIndex];
    const gemsPosition = gameData.gameEvent.gems;
    return gemsPosition.sort((a, b) => a - b);
  }

  function frogCrossingResult(result) {
    const lastRnIndex = result.randomizations.length - 1;
    const gameData = result.randomizations[lastRnIndex];
    return gameData.gameEvent;
  }

  function generatePlinkoColors(length) {
    const baseLength = plinkoBaseColors.length;
    const factor = (baseLength - 1) / (length - 1);

    const colors = Array.from({ length, }, (_, i) => {
      const baseIndex = Math.round(i * factor);
      return plinkoBaseColors[baseIndex];
    });

    return colors;
  }

  function plinkoResult(result, gameId, risk, rows) {
    const gameData = result.randomizations[rows - 1];
    if (!gameData) {
      return null;
    }
    const multiplierIndex = gameData.gameEvent.multiplierIndex;
    let resultSource = winMultipliersPlinko;
    if (gameId === 'plinko0') {
      resultSource = winMultipliersPlinko0;
    } else if (gameId === 'plinko1') {
      resultSource = winMultipliersPlinko1;
    }
    const gameResult = resultSource[rows][risk.toLowerCase()][multiplierIndex];
    const color = generatePlinkoColors(rows + 1)[multiplierIndex];
    return { gameResult, color, };
  }

  function filterDragonTowerRow(rowEggsBuffer, rowConfig) {
    return rowEggsBuffer
    .filter(column => column < rowConfig.columns)
    .slice(0, rowConfig.eggs);
  }

  function dragonTowerResult(result, difficulty) {
    const rowConfig = dragonTowerRowConfigs[difficulty];
    const numOfColumns = rowConfig.columns;

    const gameData = result.randomizations.filter(data => data.limit === numOfColumns);

    const flatRowData = gameData.flatMap((data) => {
      const filteredRow = filterDragonTowerRow(data.gameEvent.rowEggsBuffer, rowConfig);
      return Array.from({ length: numOfColumns, }, (_, i) => {
        return !filteredRow.includes(i);
      }).reverse();
    }).reverse();

    return flatRowData;
  }

  function mapDiamondColor(color) {
    // mapping the color for the cdn diamonds svg
    switch (color) {
      case 'cyan':
        return 'pink';
      default:
        return color;
    }
  }

  function diamondsResult(result) {
    return result.randomizations.map(item => mapDiamondColor(item.gameEvent.diamond));
  }

  getActiveGames();

  return {
    proveFairness,
    activeGames,
    limboResult,
    diceResult,
    minesResult,
    plinkoResult,
    kenoResult,
    dragonTowerResult,
    frogCrossingResult,
    diamondsResult,
    crashResult,
    multiplier,
    mines,
    rows,
    risk,
    stopIndex,
    difficulty,
    gameResult,
    getInitialData,
    handleUpdateClientSeed,
    handleRotateSeed,
    currency,
    gameFairnessData,
    drawRngState,
    clientSeed,
    serverSeed,
    serverSeedHash,
    nextServerSeedHash,
    nonce,
    status,
  };
});
